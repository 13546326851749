import React from 'react';

import { graphql } from 'gatsby';
import { motion } from 'framer-motion';

import Form from 'components/form';
import Banner from 'components/banner';
import ContactForm from 'components/contact-form';

import { white, grey } from 'theme/colors';
import { pageAnims } from 'theme/animations';

const ContactsPage = (props) => {
  //PROPS
  const {
    lang,
    data: {
      page: {
        contacts: { image, text },
      },
    },
  } = props;
  //PROPS

  return (
    <>
      <motion.div
        style={{ background: grey.default }}
        variants={pageAnims}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <Banner
          data={{ banner: { imageFirst: true, text, image, title: '' } }}
        />
      </motion.div>
      <div className="container">
        <div className="flex">
          <Form lang={lang} />
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.485594538616!2d-8.440439183991716!3d41.205024660491866!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd24896e6667668b%3A0x2bda5287e531a75e!2sIndustrilar%20-%20Sociedade%20Industrial%20de%20Produtos%20para%20o%20Lar%2C%20Lda!5e0!3m2!1spt-PT!2spt!4v1708937232008!5m2!1spt-PT!2spt"
              width="100% "
              height="100%"
              allowFullScreen=""
              loading="lazy"
              title="Google Map da fábrica da Industrilar"
            ></iframe>
          </div>
        </div>
      </div>
      <ContactForm lang={lang}/>
    </>
  );
};

export const query = graphql`
  query Contactos($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      contacts {
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1)
            }
          }
        }
      }
    }
  }
`;

export default ContactsPage;
