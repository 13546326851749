import styled from 'styled-components';

import { container, space } from 'theme/sizes';
import { toRem, breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  position: relative;
  max-width: ${container.default};
  width: 100%;
  margin: 0 auto ${toRem(60)};

  @media ${breakpoints.laptop} {
    width: calc(100% - 36px);
    margin: ${toRem(80)} auto ${toRem(160)};
  }
`;

export const Row = styled.div`
  display: block;
  overflow: hidden;

  h4 {
    position: relative;
    margin-bottom: 24px;
    text-align: center;
    align-self: center;

    &::before {
      content: '';
      width: 80%;
      left: 10%;
      height: 6px;
      background-color: rgb(200, 200, 200);
      bottom: 0;
      transform: translateY(8px);
      position: absolute;
      z-index: -1;
    }
  }

  .col {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &-image {
      display: block;
    }

    &--text {
      order: 1;
      padding: ${space.s} ${space.xs};
    }
  }

  @media ${breakpoints.laptop} {
    display: flex;
    align-items: center;

    .col {
      width: 50%;

      &--text {
        padding: ${space.l};
      }

      &.order1 {
        order: 1;
      }

      &.order2 {
        order: 2;
      }
    }
  }
`;
