import React, { useState } from 'react';

import { useForm } from 'react-hook-form';
import axios from 'axios';

import Popup from 'components/popup';

import copy from 'data/copy';

import { Wrapper } from './Form.styles';

const Form = (props) => {
  const { lang } = props;

  //POPSTATE
  const [response, setResponse] = useState({
    type: '',
    message: '',
    open: false,
  });

  const handleClick = () => {
    setResponse({ ...response, open: !response.open });
  };
  //POPSTATE

  //FORMHANDLING
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const clearForm = () => {
    document.getElementById('form').reset();
  };

  const onSubmit = async (e) => {
    sendMessage();
  };

  const sendMessage = () => {
    setResponse({
      type: 'loading',
      message: '',
      open: true,
    });

    var contactsFormData = new FormData();

    contactsFormData.append('fname', document.querySelector('#fname').value);
    contactsFormData.append('email', document.querySelector('#femail').value);
    contactsFormData.append('phone', document.querySelector('#fphone').value);
    contactsFormData.append(
      'subject',
      document.querySelector('#fsubject').value
    );
    contactsFormData.append(
      'message',
      document.querySelector('#fmessage').value
    );

    // console.log(document.querySelector('#fname').value);
    var config = {
      method: 'post',
      url: `https://industrilar-wp.devstudio.com.pt/wp/wp-json/contact-form-7/v1/contact-forms/128/feedback`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: contactsFormData,
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === 'mail_sent') {
          setResponse({
            type: 'success',
            open: true,
            message: copy.form.success[lang],
          });
          clearForm();
        } else {
          setResponse({
            type: 'error',
            open: true,
            message: copy.form.error[lang],
          });
        }
      })
      .catch(function (error) {
        setResponse({
          type: 'error',
          open: true,
          message: copy.form.error[lang],
        });
      });
  };
  //FORMHANDLING

  return (
    <Wrapper>
      <form className="form" id="form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-row">
          <div className="form-input">
            <input
              className="input"
              placeholder={copy.form.name.placeholder[lang]}
              id="fname"
              name="fname"
              type="text"
              {...register('fname')}
            />

            {errors.fname && <p>{copy.form.name.error[lang]}</p>}
          </div>
          <div className="form-input">
            <input
              className="input"
              placeholder={copy.form.email.placeholder[lang]}
              id="femail"
              name="femail"
              type="text"
            />

            {errors.femail && <p>{copy.form.email.error[lang]}</p>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-input">
            <input
              className="input"
              placeholder={copy.form.phone.placeholder[lang]}
              id="fphone"
              name="fphone"
              type="text"
            />

            {errors.fphone && <p>{copy.form.phone.error[lang]}</p>}
          </div>
          <div className="form-input">
            <input
              className="input"
              placeholder={copy.form.subject.placeholder[lang]}
              id="fsubject"
              name="fsubject"
              type="text"
            />

            {errors.fsubject && <p>{copy.form.subject.error[lang]}</p>}
          </div>
        </div>

        <div className="form-row">
          <div className="form-input form-input--full">
            <textarea
              className="input"
              rows={6}
              placeholder={copy.form.message.placeholder[lang]}
              id="fmessage"
              name="fmessage"
              type="text"
            />

            {errors.fmessage && <p>{copy.form.message.error[lang]}</p>}
          </div>
        </div>
        <div className="submit-button">
          <button className="button">{copy.form.submit[lang]}</button>
        </div>
      </form>
      <Popup
        show={response.open}
        toggle={() => handleClick()}
        type={response.type}
        message={response.message}
      />
    </Wrapper>
  );
};

export default Form;
