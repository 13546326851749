import React from 'react';

import HTMLReactParser from 'html-react-parser';
import { GatsbyImage } from 'gatsby-plugin-image';
import InView from 'react-intersection-observer';
import { motion } from 'framer-motion';

import { Wrapper, Row } from './Banner.styles';

const Banner = (props) => {
  //PROPS
  const {
    data: {
      banner: { imageFirst, text, image, title },
    },
  } = props;
  //PROPS

  return (
    <Wrapper>
      <Row>
        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 30 }}
                initial={{ opacity: 0, y: 30 }}
                transition={{ duration: 0.5 }}
                className={
                  imageFirst ? 'col col--text order2' : 'col col--text order1'
                }
              >
                {title && <h4>{title}</h4>}
                {text && HTMLReactParser(text)}
              </motion.div>
            );
          }}
        </InView>

        <InView threshold={0.4} triggerOnce>
          {({ inView, ref, entry }) => {
            return (
              <motion.div
                ref={ref}
                animate={
                  inView
                    ? { opacity: 1, x: 0 }
                    : { opacity: 0, x: imageFirst ? -30 : 30 }
                }
                initial={{ opacity: 0, x: 30 }}
                transition={{ duration: 0.5 }}
                className={imageFirst ? 'col col-image order1' : 'col col-image order2'}
              >
                <GatsbyImage
                  image={image?.localFile?.childImageSharp?.gatsbyImageData}
                  alt={image?.altText}
                />
              </motion.div>
            );
          }}
        </InView>
      </Row>
    </Wrapper>
  );
};

export default Banner;
