import styled from 'styled-components';

import { black } from 'theme/colors';
import { p } from 'theme/typography';
import { toRem, breakpoints } from 'utils/mixins';

export const Wrapper = styled.div`
  padding: 0 ${toRem(10)};
  .form-input {
    width: 100%;
    margin-bottom: ${toRem(40)};

    .input {
      ${p}
      background-color: transparent;
      outline: none;
      border: none;
      border-bottom: ${toRem(1)} solid ${black.default};
      width: 100%;
      color: ${black.default};
      padding: ${toRem(10)};

      ::placeholder {
        color: ${black.default};
        opacity: 1;
      }

      :-ms-input-placeholder {
        color: ${black.default};
      }

      ::-ms-input-placeholder {
        color: ${black.default};
      }
    }
  }

  .submit-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;

    button {
      cursor: pointer;
    }
  }

  @media ${breakpoints.tablet} {
    margin-top: 0;
    padding: 0 ${toRem(40)};

    .form-row {
      display: flex;
      justify-content: space-between;
    }

    .form-input {
      width: calc(50% - 10px);

      &--full {
        width: 100%;
      }

      .select {
        flex-wrap: nowrap;

        .select-label {
          width: 20%;
        }
      }
    }
  }

  @media ${breakpoints.laptop} {
    width: 50%;
  }
`;
